import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Agency } from '../../types';
import { toast } from 'react-toastify';
import { AgencyForm } from './form';
import { useApi } from 'api';

export const AgencyAdd = () => {
  const navigate = useNavigate();
  const api = useApi();
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: async (values: Omit<Agency, 'id'>) => {
      const response = await api.post<Agency>('/api/v1/agencies/', values);
      return response.data;
    },
    onSuccess: (data: Agency) => {
      toast.success('Agency Created');
      queryClient.invalidateQueries({ queryKey: ['agency'] });
      navigate(`/agency/${data.id}`);
    },
    onError: () => {
      toast.error('Error creating agency');
    },
  });

  return (
    <div className='w-full max-w-7xl p-2 border border-gray-300 rounded-md'>
      <div className='py-4'>
        <span className='text-teal-400 text-4xl'>New Agency</span> <span className='text-gray-600'>Agency</span>
      </div>
      <AgencyForm
        mutateFunction={(values) => create.mutate(values)}
        initialValues={{
          company: '',
          email: undefined,
          address: undefined,
          address2: undefined,
          state: '',
          postal: undefined,
          phone: undefined,
        }}
        goBack={() => navigate(`/`)}
      />
      <br />
    </div>
  );
};
