import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "api";
import clsx from "clsx";
import { Input, Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Agency, PaginatedResponse } from "../../types";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LoadingLine, Spin } from "components";
import { debounce } from "lodash";

export const ListAgencies = () => {
  const params = useParams<{ agencyId?: string }>();
  const navigate = useNavigate();
  const api = useApi();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<string>();
  const perPage = 20;
  const sorted = null;

  const agencies = useQuery<PaginatedResponse<Agency>>({
    queryKey: ["agency"],
    queryFn: async () => {
      const params: {
        page: number;
        per_page: number;
        sorted: string | null;
        filters: string | null;
      } = {
        page,
        per_page: perPage,
        sorted,
        filters: null,
      };
      if (filters) {
        params.filters = `{"company__icontains": "${filters}"}`;
      }
      const response = await api.get<PaginatedResponse<Agency>>(
        "/api/v1/agencies/",
        {
          params,
        },
      );
      return response.data;
    },
  });

  useEffect(() => {
    agencies.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters]);

  if (!agencies.data || agencies.isLoading) {
    return <Spin size="4xl" />;
  }

  const setSearchFilter = debounce((searchText: string) => {
    if (searchText === "") {
      setFilters(undefined);
    } else if (searchText.length > 2) {
      setFilters(searchText);
    }
  }, 500);

  return (
    <div className="h-full w-full">
      <div className="py-2">
        <span className="text-base">Agencies</span>
        <button
          type="button"
          className="btn-link float-right"
          onClick={() => navigate("/agency/add")}
        >
          Add agency
        </button>
      </div>
      <div className="bg-gray-300 px-1 py-1">
        <Input
          placeholder="Filter Agencies..."
          prefix={<SearchOutlined rev={undefined} />}
          onChange={(event) => {
            setSearchFilter(event.target.value);
          }}
        />
      </div>
      <LoadingLine isShown={agencies.isFetching} />
      <div className="h-full max-h-full overflow-y-scroll rounded-md border-2 border-gray-200">
        {agencies.data.data.map((agency) => (
          <div
            className={clsx(
              "h-10 cursor-pointer items-center border-b-2 border-gray-200 px-2 py-2 hover:bg-teal-100",
              params?.agencyId === agency.id.toString() && "bg-teal-500",
            )}
            key={agency.id}
          >
            <Link to={`/agency/${agency.id}`} className="hover:no-underline">
              <div
                className={clsx(
                  "truncate pl-2 text-base hover:no-underline",
                  params.agencyId === agency.id.toString()
                    ? "text-white"
                    : "text-gray-800",
                )}
              >
                {agency.company}
              </div>
            </Link>
          </div>
        ))}
        <Pagination
          defaultCurrent={page + 1}
          total={agencies.data?.count || 0}
          current={page + 1}
          pageSize={perPage}
          hideOnSinglePage
          onChange={(page) => setPage(page - 1)}
        />
      </div>
    </div>
  );
};
