import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Client } from '../../types';
import { toast } from 'react-toastify';
import { ClientForm } from './form';
import { Spin } from 'components';
import { useApi } from 'api';

export const ClientEdit = () => {
  const navigate = useNavigate();
  const api = useApi();
  const params = useParams<{ agencyId: string; advisorId: string; clientId: string }>();
  const queryClient = useQueryClient();

  const update = useMutation({
    mutationFn: async (values: Omit<Client, 'id' | 'agent' | 'fullName' | 'filingStatus' | 'fax'>) => {
      const response = await api.put<Client>(`/api/v1/clients/${params.clientId}/`, values);
      return response.data;
    },
    onSuccess: () => {
      toast.success('Client updated successfully');
      queryClient.invalidateQueries({
        queryKey: ['agency', params.agencyId, 'advisor', params.advisorId, 'client', params.clientId],
      });
      navigate(`/agency/${params.agencyId}/advisor/${params.advisorId}/client/${params.clientId}`);
    },
    onError: () => {
      toast.error('Error updating advisor');
    },
  });

  const client = useQuery<Client>({
    queryKey: ['agency', params.agencyId, 'advisor', params.advisorId, 'client', params.clientId],
    queryFn: async () => {
      const response = await api.get<{ data: Client }>(`/api/v1/clients/${params.clientId}/`);
      return response.data.data;
    },
    enabled: !!params.advisorId,
  });

  return (
    <>
      {!client.data || client.isLoading ? (
        <Spin size='4xl' />
      ) : (
        <div className='w-full p-2 border border-gray-300 rounded-md'>
          <div>
            <span className='text-teal-400 text-4xl'>{client.data.fullName || ''}</span>
            <span className='text-gray-400'>Client</span>
          </div>
          <ClientForm
            initialValues={client.data}
            mutateFunction={(values) => update.mutate(values)}
            goBack={() => navigate(`/agency/${params.agencyId}/advisor/${params.advisorId}/client/${params.clientId}`)}
          />
          <br />
        </div>
      )}
    </>
  );
};
