import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header, InputField } from '../../components';
import { useAuth } from '../../hooks';
import { ToastContainer } from 'react-toastify';
import { Navigate, useSearchParams } from 'react-router-dom';

const valSchema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

export const LoginPage = () => {
  const auth = useAuth();
  // eslint-disable-next-line
  const [URLSearchParams, _] = useSearchParams();

  type HandleLoginFunc = (data: { username: string; password: string }) => void;
  const handleLogin: HandleLoginFunc = ({ username, password }) => {
    auth.login(username, password);
  };

  if (auth.loggedIn) {
    return <Navigate to={URLSearchParams.get('from') ?? '/'} />;
  }

  return (
    <div>
      <Header />
      <div className='w-full h-full py-4 px-8'>
        <ToastContainer />
        <div>
          <div className='flex min-h-full flex-1 flex-col justify-center px-6 pt-6 pb-12 lg:px-8'>
            <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
              <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
                Please sign in
              </h2>
            </div>
            <Formik
              validationSchema={valSchema}
              initialValues={{ username: '', password: '' }}
              onSubmit={(values, helpers) => {
                handleLogin(values);
                helpers.setSubmitting(false);
              }}
            >
              {({ dirty, isValid, isSubmitting }) => (
                <div className='mt-6 sm:mx-auto sm:w-full sm:max-w-sm'>
                  <Form className='space-y-6'>
                    <div>
                      <InputField name='username' label='Username' type='text' />
                    </div>
                    <div>
                      <InputField name='password' label='Password' type='password' />
                    </div>
                    <div>
                      <button
                        disabled={!isValid || !dirty || isSubmitting}
                        type='submit'
                        className='btn-primary block w-full'
                      >
                        {isSubmitting && <FontAwesomeIcon icon='spinner' spin />} Login
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
