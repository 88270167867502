import { Fragment, useState, forwardRef, useImperativeHandle } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BucketReport, ReportCreateModalRef } from "types";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "api";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { ReportDetailForm } from "components";
import { toast } from "react-toastify";

type ReportCreateModalProps = {
  agencyId: string | undefined;
  advisorId: string | undefined;
  clientId: string | undefined;
};

export const _ReportCreateModal: React.ForwardRefRenderFunction<
  ReportCreateModalRef,
  ReportCreateModalProps
> = (props, ref) => {
  const { agencyId, advisorId, clientId } = props;
  const api = useApi();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const createReport = useMutation<BucketReport, AxiosError, BucketReport>({
    mutationFn: async (values) => {
      const response = await api.post<BucketReport>(
        `/api/v1/clients/${clientId}/bucket-reports/`,
        values,
      );
      return response.data;
    },
    onSuccess: (data) => {
      navigate(
        `/agency/${agencyId}/advisor/${advisorId}/client/${clientId}/report/${data.id}`,
      );
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        toast.error(
          "There was an error creating the report. Please try again.",
        );
      }
    },
  });

  const confirmCallback = (values: BucketReport) => {
    createReport.mutate(values);
    cancel();
  };

  const cancel = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      setOpen: (open: boolean) => {
        setOpen(open);
      },
    };
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={cancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <ReportDetailForm
                  confirmCallback={confirmCallback}
                  cancel={cancel}
                  report={
                    {
                      name: "",
                      approach: "input_only",
                      goalAmount: 0,
                      inflationRate: 0,
                      availableBalance: 0,
                    } as BucketReport
                  }
                  title="Create New Bucket Report"
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const ReportCreateModal = forwardRef(_ReportCreateModal);
