import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Agent } from '../../types';
import { toast } from 'react-toastify';
import { AdvisorForm } from './form';
import { Spin } from 'components';
import { useApi } from 'api';

export const AdvisorEdit = () => {
  const navigate = useNavigate();
  const api = useApi();
  const params = useParams<{ agencyId: string; advisorId: string }>();
  const queryClient = useQueryClient();

  const update = useMutation({
    mutationFn: async (values: Omit<Agent, 'id' | 'agency' | 'fullName'>) => {
      await api.put<Agent>(`/api/v1/agents/${params.advisorId}/`, values);
    },
    onSuccess: () => {
      toast.success('Agency updated successfully');
      queryClient.invalidateQueries({ queryKey: ['agency', params.agencyId, 'advisor', params.advisorId] });
      toast.success('Advisor Updated');
      navigate(`/agency/${params.agencyId}/advisor/${params.advisorId}`);
    },
    onError: () => {
      toast.error('Error updating advisor');
    },
  });

  const advisor = useQuery<Agent>({
    queryKey: ['agency', params.agencyId, 'advisor', params.advisorId],
    queryFn: async () => {
      const response = await api.get<{ data: Agent }>(`/api/v1/agents/${params.advisorId}/`);
      return response.data.data;
    },
    enabled: !!params.advisorId,
  });

  return (
    <>
      {!advisor.data || advisor.isLoading ? (
        <Spin size='4xl' />
      ) : (
        <div className='w-full p-2 border border-gray-300 rounded-md'>
          <div>
            <span className='text-teal-400 text-4xl'>{advisor.data.fullName}</span>{' '}
            <span className='text-gray-600'>Advisor</span>
          </div>
          <AdvisorForm
            initialValues={{
              firstName: advisor.data.firstName,
              lastName: advisor.data.lastName,
              phone: advisor.data.phone,
              email: advisor.data.email,
            }}
            mutateFunction={(values) => update.mutate(values)}
            goBack={() => navigate(`/agency/${params.agencyId}/advisor/${params.advisorId}`)}
          />
        </div>
      )}
    </>
  );
};
