import {
  createBrowserRouter,
  RouterProvider,
  isRouteErrorResponse,
  useRouteError,
  useNavigate,
} from 'react-router-dom';

import { Footer, Header } from '../../components';
import {
  LoginPage,
  BucketToolPage,
  ProfileManagementPage,
  AuthLayout,
  AgenciesList,
  AgencyDetail,
  AdvisorDetail,
  ClientDetail,
  AgencyEdit,
  AdvisorEdit,
  ClientEdit,
  AgencyAdd,
  AdvisorAdd,
  ClientAdd,
  ProfilePage,
} from '../../pages';
import { NoAgencySelected } from 'pages/AgenciesList/no_agency_selected';

const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <Header />
        <div className='w-full h-full py-4 px-8'>
          <div className='text-4xl'>Oops!</div>
          <div className='pb-12'>
            <div className='text-lg m-4'>
              {error.status}: {error.statusText}
            </div>
            {error.data?.message && <p>{error.data.message}</p>}
            <button onClick={() => window.location.reload()} className='btn-primary px-2 mr-4'>
              Reload
            </button>
            <button onClick={() => navigate('/')} className='btn-outline px-2'>
              Go Home
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Oops</div>;
  }
};

export const Main = () => {
  const router = createBrowserRouter([
    {
      element: <AuthLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: '/',
          element: <ProfileManagementPage />,
          children: [
            {
              path: '',
              element: <AgenciesList />,
              children: [
                {
                  path: '',
                  element: <NoAgencySelected />,
                },
                {
                  path: 'agency/add',
                  element: <AgencyAdd />,
                },
                {
                  path: 'agency/:agencyId',
                  element: <AgencyDetail />,
                },
                {
                  path: 'agency/:agencyId/edit',
                  element: <AgencyEdit />,
                },
                {
                  path: 'agency/:agencyId/advisor/add',
                  element: <AdvisorAdd />,
                },
                {
                  path: 'agency/:agencyId/advisor/:advisorId',
                  element: <AdvisorDetail />,
                },
                {
                  path: 'agency/:agencyId/advisor/:advisorId/edit',
                  element: <AdvisorEdit />,
                },
                {
                  path: 'agency/:agencyId/advisor/:advisorId/client/add',
                  element: <ClientAdd />,
                },
                {
                  path: 'agency/:agencyId/advisor/:advisorId/client/:clientId',
                  element: <ClientDetail />,
                },
                {
                  path: 'agency/:agencyId/advisor/:advisorId/client/:clientId/edit',
                  element: <ClientEdit />,
                },
              ],
            },
            {
              path: 'profile',
              element: <ProfilePage />,
            },
            {
              path: 'agency/:agencyId/advisor/:advisorId/client/:clientId/report/add',
              element: <BucketToolPage />,
            },
            {
              path: 'agency/:agencyId/advisor/:advisorId/client/:clientId/report/:reportId',
              element: <BucketToolPage />,
            },
          ],
        },
        {
          path: '/login',
          element: <LoginPage />,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <Footer />
    </>
  );
};
