import { InputField } from "components";
import { Formik, Form } from "formik";
import { useAuth } from "hooks";
import numeral from "numeral";
import { BucketReport, RecommendModalRef, ReportCreateModalRef } from "types";
import { BASE_URL } from "../../constants";
import { RecommendModal } from "./modal_recommend";
import { useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api";
// import { Link } from "react-router-dom";
import { ReportUpdateModal } from "./report_edit_modal";

interface ReportDetailProps {
  report: BucketReport;
}
export const ReportDetail: React.FC<ReportDetailProps> = (props) => {
  const { report } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  const approaches: { [key: string]: string } = {
    input_only: "Input Only",
    income: "Income Goal",
    available: "Available Funds",
  };

  const { token } = useAuth();
  const recomendModalRef = useRef<RecommendModalRef>(null);
  const updateReportModalRef = useRef<ReportCreateModalRef>(null);
  const updateReport = useMutation({
    mutationFn: async (values: BucketReport) => {
      return await api.put(
        `/api/v1/clients/${report.customer.id}/bucket-reports/${report.id}/`,
        values,
      );
    },
    onSuccess: () => {
      recomendModalRef.current?.setOpen(false);
      queryClient.invalidateQueries({
        queryKey: [
          "agency",
          report.customer.agencyId,
          "advisor",
          report.customer.agentId,
          "client",
          report.customer.id.toString(),
          "report",
          report.id.toString(),
        ],
      });
    },
  });
  const viewPDF = (includeExisting: boolean) => {
    window.open(
      `${BASE_URL}api/v1/clients/${report.customer.id}/bucket-reports/${report.id}/pdf/?jwt=${token}&include_existing=${includeExisting}`,
      "_blank",
      "noreferrer",
    );
  };

  return (
    <div className="min-w-72 h-full w-96">
      <RecommendModal
        ref={recomendModalRef}
        saveRecommendations={(recommendations) => {
          updateReport.mutate({
            ...report,
            saveACopy: false,
            recommendations,
          } as BucketReport);
        }}
      />
      <ReportUpdateModal report={report} ref={updateReportModalRef} />
      <div className="flex flex-col justify-between">
        <div className="ml-2 flex-grow rounded-md border-2 border-gray-300 px-3 py-2">
          <div className="flex flex-row justify-between">
            <div className="font-bold">Report Settings</div>
            <div>
              <button
                className="btn-link"
                onClick={() => updateReportModalRef.current?.setOpen(true)}
              >
                Edit
              </button>
            </div>
          </div>
          <div className="mt-2 flex-row justify-between">
            <div className="text-sm text-gray-800">Name</div>
            <div className="text-sm text-gray-500">{report.name}</div>
          </div>
          <div className="mt-2 flex-row justify-between">
            <div className="text-sm text-gray-800">Approach</div>
            <div className="text-sm text-gray-500">
              {approaches[report.approach]}
            </div>
          </div>
          {["available", "income"].includes(report.approach) && (
            <div className="mt-2 flex-row justify-between">
              <div className="text-sm text-gray-800">Available</div>
              <div className="text-sm text-gray-500">
                {numeral(report.availableBalance).format("$0,0")}
              </div>
            </div>
          )}
          {report.approach === "income" && (
            <>
              <div className="mt-2 flex-row justify-between">
                <div className="text-sm text-gray-800">Goal</div>
                <div className="text-sm text-gray-500">
                  {numeral(report.goalAmount).format("$0,0")}
                </div>
              </div>
              <div className="mt-2 flex-row justify-between">
                <div className="text-sm text-gray-800">Inflation</div>
                <div className="text-sm text-gray-500">
                  {numeral(report.inflationRate).format("0,0.000%")}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="ml-2 mt-2 flex-grow rounded-md border-2 border-gray-300 px-3 py-2">
          <div className="font-bold">Summary</div>
          <div className="mt-2 flex-row justify-between">
            <div className="text-sm text-gray-800">Total Required</div>
            <div className="text-sm text-gray-500">
              {numeral(report.stats.totalPremium ?? 0).format("$0,0")}
            </div>
          </div>
          <div className="mt-2 flex-row justify-between">
            <div className="text-sm text-gray-800">Ending Balance</div>
            <div className="text-sm text-gray-500">
              {numeral(report.stats.totalBalance ?? 0).format("$0,0")}
            </div>
          </div>
          <div className="mt-2 flex-row justify-between">
            <div className="text-sm text-gray-800">Years of Income</div>
            <div className="text-sm text-gray-500">
              {numeral(report.stats.incomeYears ?? 0).format("0")}
            </div>
          </div>
        </div>

        <div className="ml-2 mt-2 flex-shrink-0 rounded-md border-2 border-gray-300 px-3 py-2">
          <div className="flex-grow pb-4">
            <div className="pb-2 font-bold">Recommendations</div>
            {report.recommendations?.map((recommendation, index) => (
              <div className="py-1 text-sm text-gray-500" key={index}>
                {index + 1}. {recommendation}
              </div>
            ))}
            <div
              className="btn-link mt-2 text-center"
              onClick={() => {
                recomendModalRef.current?.setRecommendations(
                  report.recommendations || [],
                );
                recomendModalRef.current?.setOpen(true);
              }}
            >
              Edit Recommendations
            </div>
          </div>
        </div>
        <div className="ml-2 mt-2 flex-shrink-0 rounded-md border-2 border-gray-300 px-3 py-2">
          <div className="mb-2 font-bold">PDF Report</div>

          <div className="">
            <Formik
              initialValues={{
                includeExisting: false,
              }}
              onSubmit={(values) => {
                viewPDF(values.includeExisting);
              }}
            >
              <Form>
                <InputField
                  type="checkbox"
                  label="Include Existing Income"
                  name="includeExisting"
                  className="mb-2 text-xs lg:text-sm"
                />
                <button type="submit" className="btn-secondary w-full">
                  View PDF
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
