import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

interface SpinProps {
  size?: 'xs' | 'sm' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | '8xl' | '9xl';
  color?:
    | 'white'
    | 'black'
    | 'gray'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'green'
    | 'teal'
    | 'blue'
    | 'indigo'
    | 'purple'
    | 'pink';
}
export const Spin: React.FC<SpinProps> = (props) => {
  const { size, color } = props;
  return useMemo(
    () => (
      <div
        className={`flex justify-center align-middle w-full h-full m-auto text-${size || 'base'} text-${
          color || 'teal'
        }-300`}
      >
        <FontAwesomeIcon icon={['fas', 'spinner']} spin />
      </div>
    ),
    [size, color]
  );
};
