import { Dialog } from "@headlessui/react";
import {
  XMarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { InputField } from "components/InputField";
import { Formik } from "formik";
import { Form } from "react-router-dom";
import { BucketReport } from "types";
import * as Yup from "yup";

interface ReportDetailFormProps {
  confirmCallback: (values: BucketReport) => void;
  cancel: () => void;
  report: BucketReport;
  title: string;
}
export const ReportDetailForm: React.FC<ReportDetailFormProps> = (props) => {
  const { confirmCallback, cancel, report, title } = props;
  return (
    <Formik
      initialValues={{ ...report, saveACopy: false }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("This field is required"),
        approach: Yup.string()
          .oneOf(["input_only", "income", "available"])
          .required("This field is required"),
        goalAmount: Yup.number().when("approach", {
          is: "income",
          then: (schema) =>
            schema
              .typeError("Must be a number")
              .min(0, "Cannot be a negative value.")
              .required("This field is required"),
          otherwise: (schema) => schema.nullable(),
        }),
        inflationRate: Yup.number().when("approach", {
          is: "income",
          then: (schema) =>
            schema
              .typeError("Must be a number")
              .min(0, "Cannot be a negative value.")
              .max(100, "Cannot be greater than 100%")
              .required("This field is required"),
          otherwise: (schema) => schema.nullable(),
        }),
        availableBalance: Yup.number().when("approach", {
          is: (val: string) => ["available", "income"].includes(val),
          then: (schema) =>
            schema
              .typeError("Must be a number")
              .min(0, "Cannot be a negative value.")
              .required("This field is required"),
          otherwise: (schema) => schema.nullable(),
        }),
      })}
      onSubmit={(values, helpers) => {
        confirmCallback(values);
      }}
    >
      {({
        values,
        resetForm,
        isSubmitting,
        isValid,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <Form>
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => {
                  resetForm();
                  cancel();
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-teal-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="gap-4 text-base font-semibold capitalize leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2 gap-4 py-4">
                  <InputField
                    label="Name"
                    name="name"
                    type="text"
                    className="mb-2"
                  />
                  <InputField
                    label="Goal"
                    name="approach"
                    type="select"
                    selectValues={[
                      { id: "income", name: "Income Goal" },
                      { id: "available", name: "Available Funds" },
                      { id: "input_only", name: "Input Only" },
                    ]}
                    className="mb-2"
                  />
                  {values.approach === "income" && (
                    <>
                      <InputField
                        label="Goal Amount"
                        name="goalAmount"
                        type="number"
                        leftIcon="$"
                        className="mb-2"
                      />
                      <InputField
                        label="Inflation"
                        name="inflationRate"
                        type="number"
                        leftIcon="%"
                        className="mb-2"
                      />
                    </>
                  )}
                  {["available", "income"].includes(values.approach) && (
                    <InputField
                      label="Available Funds"
                      name="availableBalance"
                      type="number"
                      leftIcon="$"
                      className="mb-2"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 gap-2 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn-primary w-40"
                disabled={!isValid || isSubmitting}
              >
                Save
              </button>
              {report.id && (
                <button
                  type="submit"
                  onClick={() => {
                    setFieldValue("saveACopy", true);
                    handleSubmit();
                  }}
                  className="btn-secondary w-40"
                  disabled={!isValid || isSubmitting}
                >
                  Save a Copy
                </button>
              )}
              <button
                type="button"
                className="btn-outline w-40"
                disabled={isSubmitting}
                onClick={() => {
                  resetForm();
                  cancel();
                }}
              >
                Cancel
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
