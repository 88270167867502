import { Fragment, useState, forwardRef, useImperativeHandle } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BucketReportSegment, PreviewModalRef } from "types";
import { Table } from "antd";
import numeral from "numeral";

export const _PreviewModal: React.ForwardRefRenderFunction<PreviewModalRef> = (
  props,
  ref,
) => {
  const [open, setOpen] = useState(false);
  const [previewType, setPreviewType] = useState<"parsed" | "calc">();
  const [segmentData, setSegmentData] = useState<BucketReportSegment>(
    {} as BucketReportSegment,
  );

  useImperativeHandle(ref, () => {
    return {
      setPreviewType: (previewType: "parsed" | "calc") => {
        setPreviewType(previewType);
      },
      setSegmentData: (data: BucketReportSegment) => {
        setSegmentData(data);
      },
      setOpen: (open: boolean) => {
        setOpen(open);
      },
    };
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold capitalize leading-6 text-gray-900"
                    >
                      {segmentData.name ||
                        `Segment - ${segmentData.segmentType}`}
                    </Dialog.Title>
                    <div className="mt-2">
                      {previewType === "calc" ? (
                        <Table
                          rootClassName="w-full"
                          rowClassName="even:bg-teal-50"
                          size="middle"
                          pagination={{ pageSize: 20 }}
                          dataSource={segmentData.segmentCalculation}
                          columns={[
                            {
                              title: "Year",
                              dataIndex: "calendarYear",
                              key: "calendarYear",
                              align: "right",
                            },
                            {
                              title: "Age",
                              dataIndex: "age",
                              key: "age",
                              align: "right",
                            },
                            {
                              title: "Income",
                              dataIndex: "monthlyIncome",
                              key: "monthlyIncome",
                              align: "right",
                              render: (value) =>
                                numeral(value * 12).format("$0,0.00"),
                            },
                            {
                              title: "Liquidity",
                              dataIndex: "liquidity",
                              key: "liquidity",
                              align: "right",
                              render: (value) =>
                                numeral(value).format("$0,0.00"),
                            },
                            {
                              title: "Account Value",
                              dataIndex: "accountValue",
                              key: "accountValue",
                              align: "right",
                              render: (value) =>
                                numeral(value).format("$0,0.00"),
                            },
                          ]}
                        />
                      ) : previewType === "parsed" ? (
                        <Table
                          rootClassName="w-full"
                          rowClassName="even:bg-teal-50"
                          size="middle"
                          pagination={{ pageSize: 20 }}
                          dataSource={segmentData.segmentData.parsedFile}
                          columns={[
                            {
                              title: "Age",
                              dataIndex: "age",
                              key: "age",
                              align: "right",
                            },
                            {
                              title: "Premium",
                              dataIndex: "premium",
                              key: "premium",
                              align: "right",
                            },
                            {
                              title: "Income",
                              dataIndex: "annualIncome",
                              key: "annualIncome",
                              align: "right",
                              render: (value) =>
                                numeral(value).format("$0,0.00"),
                            },
                            {
                              title: "Surrender",
                              dataIndex: "surrenderValue",
                              key: "surrenderValue",
                              align: "right",
                              render: (value) =>
                                numeral(value).format("$0,0.00"),
                            },
                            {
                              title: "Accumulation",
                              dataIndex: "accumulationValue",
                              key: "accumulationValue",
                              align: "right",
                              render: (value) =>
                                numeral(value).format("$0,0.00"),
                            },
                            {
                              title: "Death Benefit",
                              dataIndex: "deathBenefit",
                              key: "deathBenefit",
                              align: "right",
                              render: (value) =>
                                numeral(value).format("$0,0.00"),
                            },
                          ]}
                        />
                      ) : (
                        <div className="text-center text-gray-400">
                          No data available
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const PreviewModal = forwardRef(_PreviewModal);
