import { useMemo } from "react";
import { Client } from "types";
import numeral from "numeral";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ClientDetailColumnProps {
  client: Client;
}
export const ClientDetailColumn: React.FC<ClientDetailColumnProps> = (
  props,
) => {
  const { client } = props;

  const monthlyIncome = client.assetIncomeSources
    .map((incomeSource) => incomeSource.monthlyIncome)
    .reduce((a, b) => a + b, 0);

  const currentTaxRate =
    (client.federalTaxRateCurrent ??
      0 + (client.stateLocalTaxRateCurrent ?? 0)) / 100;
  const retirementTaxRate =
    (client.federalTaxRateRetirement ??
      0 + (client.stateLocalTaxRateCurrent ?? 0)) / 100;

  return useMemo(
    () => (
      <div className="flex justify-between">
        <div className="w-80">
          <div className="flex justify-between">
            <span className="text-sm">{client.fullName}</span>
            <span className="text-right text-sm">
              {client.gender === 1 ? "Male" : "Female"}, {client.currentAge}
            </span>
          </div>
          {client.spouseFullName && (
            <div className="flex justify-between">
              <span className="text-sm">{client.spouseFullName}</span>
              <span className="text-right text-sm">
                {client.spouseGender === 1 ? "Male" : "Female"},{" "}
                {client.spouseAge}
              </span>
            </div>
          )}
        </div>
        <div className="flex-row justify-between">
          <div className="flex-col justify-start text-sm">
            Monthly Income{" "}
            {/* <button
              data-popover-target="popover-monthly-income"
              className="none rounded-lg text-xs text-gray-500 transition-all hover:bg-teal-500/10 active:bg-teal-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              data-ripple-light="true"
            >
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </button>
            <div
              data-popover="popover-monthly-income"
              role="tooltip"
              data-popover-placement="bottom"
              className="invisible absolute z-10 w-max whitespace-normal break-words rounded-lg border border-blue-gray-50 bg-white p-4 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none"
            >
              This is a very beautiful popover, show some love.
            </div> */}
          </div>
          <div className="text-right text-sm">
            {numeral(monthlyIncome || 0).format("$0,0.00")}
          </div>
        </div>
        {/* <div className="flex-row justify-between">
          <div className="flex-col justify-start text-sm">
            Vault{" "}
            <button
              data-popover-target="popover-monthly-income"
              className="none rounded-lg text-xs text-gray-500 transition-all hover:bg-gray-500/10 active:bg-gray-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              data-ripple-light="true"
            >
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </button>
          </div>
          <div className="text-right text-sm">
            {numeral(0).format("$0,0.00")}
          </div>
        </div> */}
        <div className="flex-row justify-between">
          <div className="flex-col justify-start text-sm">
            Current Tax Rate{" "}
            {/* <button
              data-popover-target="popover-monthly-income"
              className="none rounded-lg text-xs text-gray-500 transition-all hover:bg-gray-500/10 active:bg-gray-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              data-ripple-light="true"
            >
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </button> */}
          </div>
          <div className="text-right text-sm">
            {numeral(currentTaxRate || 0).format("0.000%")}
          </div>
        </div>
        <div className="flex-row justify-between">
          <div className="flex-col justify-start text-sm">
            Retirement Tax Rate{" "}
            {/* <button
              data-popover-target="popover-monthly-income"
              className="none rounded-lg text-xs text-gray-500 transition-all hover:bg-gray-500/10 active:bg-gray-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              data-ripple-light="true"
            >
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </button> */}
          </div>
          <div className="text-right text-sm">
            {numeral(retirementTaxRate || 0).format("0.000%")}
          </div>
        </div>
      </div>
    ),
    [client, monthlyIncome, currentTaxRate, retirementTaxRate],
  );
};
