import { Form, Formik } from 'formik';
import { InputField } from 'components';
import * as Yup from 'yup';
import { Agent } from '../../types';

interface AdvisorFormProps {
  initialValues: Omit<Agent, 'id' | 'fullName' | 'agency'>;
  mutateFunction: (values: Omit<Agent, 'id' | 'fullName' | 'agency'>) => void;
  goBack: () => void;
}

export const AdvisorForm: React.FC<AdvisorFormProps> = (props) => {
  const { initialValues, mutateFunction, goBack } = props;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        mutateFunction(values);
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().min(2, 'Must be at least 2 characters long.').required('This field is required'),
        lastName: Yup.string().min(2, 'Must be at least 2 characters long.').required('This field is required'),
        phone: Yup.string().nullable(),
        email: Yup.string().email('Must be a valid email address').nullable(),
      })}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <div className='py-4 text-gray-600 text-base'>Advior Information</div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <InputField name='firstName' label='First Name*' type='text' />
            <InputField name='lastName' label='Last Name*' type='text' />
            <InputField name='email' label='Email' type='text' />
            <InputField name='phone' label='Phone Number' type='text' />
          </div>
          <div className='flex flex-row justify-end py-4 gap-4'>
            <button
              type='button'
              onClick={() => goBack()}
              className='btn-outline w-40'
              disabled={!isValid || isSubmitting}
            >
              Cancel
            </button>
            <button type='submit' className='btn-primary w-40' disabled={!isValid || isSubmitting}>
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
