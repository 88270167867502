import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { Agency } from "../../types";
import { AdvisorList } from "./advisor_list";
import { Spin } from "components";
import { useApi } from "api";

export const AgencyDetail: React.FC = () => {
  const api = useApi();
  const params = useParams<{ agencyId: string }>();

  const agency = useQuery<Agency>({
    queryKey: ["agency", params.agencyId],
    queryFn: async () => {
      const response = await api.get<{ data: Agency }>(
        `/api/v1/agencies/${params.agencyId}/`,
      );
      return response.data.data;
    },
    enabled: !!params.agencyId,
  });

  return (
    <div className="flex w-full">
      {!agency.data || agency.isLoading ? (
        <Spin size="4xl" />
      ) : (
        <div className="w-full rounded-md border border-gray-300 p-2">
          <div className="flex flex-row">
            <div className="grow">
              <span className="text-4xl text-teal-400">
                {agency.data.company}
              </span>{" "}
              <span className="text-gray-400">Agency</span>
            </div>
            <div className="align-middle">
              <Link to={`/agency/${params.agencyId}/edit`}>
                <div className="btn-link">Edit</div>
              </Link>
            </div>
          </div>
          <div>
            <div>{agency.data.email}</div>
          </div>
          <div>
            <div>{agency.data.address}</div>
            {agency.data.address2 && <div>{agency.data.address2}</div>}
            <div>
              {agency.data.city}, {agency.data.state} {agency.data.postal}
            </div>
          </div>
          <div>
            <div>{agency.data.phone}</div>
          </div>
          <br />
          <div>
            <div>
              <AdvisorList agencyId={params.agencyId} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
