import { useState } from "react";

interface LoadingLineProps {
  isShown: boolean;
}

export const LoadingLine: React.FC<LoadingLineProps> = (props) => {
  const [loaderWidth, setLoaderWidth] = useState("0%");

  setTimeout(() => {
    setLoaderWidth(loaderWidth === "0%" ? "100%" : "0%");
  }, 500);

  if (!props.isShown) {
    return null;
  }

  return (
    <div className="h-1 w-full rounded-full bg-gray-200">
      <div
        className="h-1 rounded-full bg-teal-600 transition-[width] duration-500 ease-linear"
        style={{ width: loaderWidth }}
      ></div>
    </div>
  );
};
