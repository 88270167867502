import { useNavigate } from "react-router-dom";
import { ListAgencies } from "./list_agencies";

export const NoAgencySelected: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="hidden items-center justify-center md:flex">
        <div className="m-auto">
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No agency selected
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by selecting and agency or creating a new agency.
            </p>
            <div className="mt-6">
              <button
                type="button"
                onClick={() => navigate("/agency/add")}
                className="btn-outline inline-flex"
              >
                <svg
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                </svg>
                New Agency
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-full w-full md:hidden">
        <ListAgencies />
      </div>
    </>
  );
};
