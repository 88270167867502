import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { FieldHookConfig, useField } from "formik";
import { ChangeEvent, useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface InputFieldProps {
  name: string;
  value?: string;
  label: string;
  className?: string;
  fieldClassName?: string;
  disabled?: boolean;
  leftIcon?: React.ReactNode | string;
  uploadFile?: (file: File) => void;
  viewFileValues?: () => void;
  selectValues?: { id: string | number; name: string }[];
  type:
    | "text"
    | "password"
    | "email"
    | "number"
    | "select"
    | "radio"
    | "checkbox"
    | "file";
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const InputField: React.FC<InputFieldProps> = (props) => {
  const {
    label,
    type,
    name,
    value,
    disabled,
    selectValues,
    leftIcon,
    className,
    fieldClassName,
    onBlur,
    onChange,
    uploadFile,
    viewFileValues,
  } = props;
  const config: FieldHookConfig<any> = { name, type };
  if (value) {
    config.value = value;
  }
  const [field, meta, helpers] = useField(config);

  const onFileDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!uploadFile) {
        return;
      }
      uploadFile(acceptedFiles[0]);
    },
    [uploadFile],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onFileDrop });

  const extensionValidator = (file: File) => {
    const extension = file.name.split(".").pop();
    return ["csv", "xlsx"].includes(extension || "none");
  };

  const handleBlur = (e: any) => {
    if (onBlur) {
      onBlur();
    } else {
      field.onBlur(e);
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (onChange) {
      onChange(e);
    } else {
      field.onChange(e);
    }
  };

  const renderLeftIcon = () => {
    if (leftIcon && !["select", "radio", "checkbox", "file"].includes(type)) {
      return (
        <div className="absolute left-2 top-2/4 grid h-5 w-5 -translate-y-2/4 place-items-center text-blue-gray-500">
          {leftIcon}
        </div>
      );
    }
  };

  const renderLabel = () => {
    switch (type) {
      case "file":
        return null;
      case "radio":
        return (
          <label
            className={clsx(
              "before:content[' '] after:content[' '] pointer-events-none absolute -top-1.5 left-0 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:block before:h-1.5 before:w-2.5 before:transition-all after:pointer-events-none after:ml-1 after:mt-[6.5px] after:block after:h-1.5 after:w-2.5 after:flex-grow after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-teal-500 peer-focus:before:border-l-2 peer-focus:before:border-t-2 peer-focus:before:border-teal-500 peer-focus:after:border-r-2 peer-focus:after:border-t-2 peer-focus:after:border-teal-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500",
              meta.touched && meta.error && "text-red-600",
              disabled && "text-gray-100",
            )}
          >
            {label}
          </label>
        );
      case "checkbox":
        return (
          <div className="inline-flex h-full items-center pt-1 align-top">
            <label className="cursor-pointer select-none truncate font-light text-gray-700">
              {label}
            </label>
          </div>
        );
      default:
        return (
          <label
            className={clsx(
              "before:content[' '] after:content[' '] pointer-events-none absolute -top-1.5 left-0 flex h-full w-full select-none text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mr-1 before:mt-[6.5px] before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-l before:border-t before:border-blue-gray-200 before:transition-all after:pointer-events-none after:ml-1 after:mt-[6.5px] after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-r after:border-t after:border-blue-gray-200 after:transition-all disabled:text-gray-100 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-teal-500 peer-focus:before:border-l-2 peer-focus:before:border-t-2 peer-focus:before:border-teal-500 peer-focus:after:border-r-2 peer-focus:after:border-t-2 peer-focus:after:border-teal-500 peer-disabled:text-gray-400 peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-gray-400",
              leftIcon && "pl-0 peer-placeholder-shown:pl-4 peer-focus:pl-0",
              meta.touched &&
                meta.error &&
                "text-red-600 before:border-red-600 after:border-red-600",
            )}
          >
            {label}
          </label>
        );
    }
  };

  const renderField = () => {
    switch (type) {
      case "file":
        if (meta.value === undefined) {
          return (
            <div
              className={clsx(
                "mt-4 flex h-28 flex-col justify-center rounded-md border-2 border-dashed border-gray-300 text-center align-middle text-sm hover:border-teal-300 ",
                disabled && "hover:border-gray-300",
              )}
              {...getRootProps({
                multiple: false,
                validator: extensionValidator,
                accept:
                  "text/*, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })}
            >
              <input {...getInputProps()} disabled={disabled} />
              Drop .csv file here or click to upload
            </div>
          );
        } else {
          return (
            <div
              className={clsx(
                "mt-4 flex h-28 flex-col justify-around text-center align-middle text-sm",
              )}
            >
              <div onClick={viewFileValues} className="btn-link flex flex-col">
                <FontAwesomeIcon icon={["fas", "eye"]} size="2x" />
                View Parsed Values
              </div>
              <div onClick={() => helpers.setValue("")} className="btn-link">
                Reset Parsed Values
              </div>
            </div>
          );
        }
      case "select":
        return (
          <select
            {...field}
            className={clsx(
              "peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-teal-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50",
              meta.error && meta.touched
                ? "border-2 border-red-600 bg-red-50 placeholder-shown:border-2 placeholder-shown:border-red-600 placeholder-shown:border-t-red-600 focus:border-2 focus:border-red-500 focus:border-t-transparent focus:outline-0"
                : "bg-transparent",
            )}
            placeholder=" "
            value={meta.value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          >
            {!meta.value && <option value="">--- Select ---</option>}
            {selectValues &&
              selectValues.map((item) => (
                <option value={item.id} key={`option.${item.id}`}>
                  {item.name}
                </option>
              ))}
          </select>
        );
      case "radio":
        return (
          <div className="flex gap-8">
            {selectValues?.map((item) => (
              <div
                className={clsx("inline-flex items-center")}
                key={`radio.${item.id}`}
              >
                <label
                  className="relative flex cursor-pointer items-center rounded-full p-3"
                  data-ripple-dark="true"
                >
                  <input
                    {...field}
                    type="radio"
                    checked={item.id === meta.value}
                    value={item.id}
                    onChange={() => helpers.setValue(item.id)}
                    className={clsx(
                      "before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-teal-500 transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-teal-500 checked:before:bg-teal-500 hover:before:opacity-10",
                      meta.error && meta.touched
                        ? "border-2 border-red-600 bg-red-50 placeholder-shown:border-2 placeholder-shown:border-red-600 placeholder-shown:border-t-red-600 focus:border-2 focus:border-red-500 focus:border-t-transparent focus:outline-0"
                        : "bg-transparent",
                      disabled &&
                        "cursor-not-allowed border-gray-400 text-gray-400 checked:border-gray-400 checked:before:bg-gray-300 hover:before:opacity-0",
                    )}
                    placeholder=""
                    onBlur={handleBlur}
                    disabled={disabled}
                  />
                  <div
                    className={clsx(
                      "pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-teal-500 opacity-0 transition-opacity peer-checked:opacity-100",
                      disabled && "text-gray-300",
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                    >
                      <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                    </svg>
                  </div>
                </label>
                <label
                  className={clsx(
                    "mt-px cursor-pointer select-none font-light text-gray-700",
                    meta.error && meta.touched && "text-red-600 ",
                    disabled && "cursor-not-allowed text-gray-300",
                  )}
                >
                  {item.name}
                </label>
              </div>
            ))}
          </div>
        );
      case "checkbox":
        return (
          <div className={clsx("inline-flex items-center")}>
            <label
              className="relative flex cursor-pointer items-center rounded-full p-3"
              data-ripple-dark="true"
            >
              <input
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-teal-500 checked:bg-teal-500 checked:before:bg-teal-500 hover:before:opacity-10"
                {...field}
                type="checkbox"
                placeholder=" "
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
              />
              <div className="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>
        );
      default:
        return (
          <input
            type={type}
            {...field}
            className={clsx(
              "peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-teal-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50",
              leftIcon && "pl-7",
              fieldClassName,
              type === "number" && "no-number-spin text-right",
              meta.error && meta.touched
                ? "border-2 border-red-600 bg-red-50 placeholder-shown:border-2 placeholder-shown:border-red-600 placeholder-shown:border-t-red-600 focus:border-2 focus:border-red-500 focus:border-t-transparent focus:outline-0"
                : "bg-transparent",
            )}
            placeholder=" "
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />
        );
    }
  };

  return (
    <div className={fieldClassName}>
      <div
        className={clsx(
          type !== "file" && "relative h-10 w-full",
          className,
          !["file", "radio", "checkbox"].includes(type) && "min-w-[60px]",
        )}
        title={meta.error}
      >
        {renderLeftIcon()}
        {renderField()}
        {renderLabel()}
      </div>
    </div>
  );
};
