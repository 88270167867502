import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Input, Table, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { PaginatedResponse, Client } from "../../types";
import { LoadingLine, Spin } from "components";
import { useApi } from "api";
import { debounce } from "lodash";

interface ClientListProps {
  agencyId: string | undefined;
  advisorId: string | undefined;
}

export const ClientList: React.FC<ClientListProps> = (props) => {
  const { agencyId, advisorId } = props;
  const api = useApi();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<string>();
  const perPage = 20;
  const sorted = null;

  const clients = useQuery<PaginatedResponse<Client>>({
    queryKey: ["agency", agencyId, "advisor", advisorId, "client"],
    queryFn: async () => {
      const params: {
        page: number;
        per_page: number;
        sorted: string | null;
        filters: string | null;
      } = {
        page,
        per_page: perPage,
        sorted,
        filters: null,
      };
      if (filters) {
        params.filters = `{"last_name__icontains": "${filters}"}`;
      }

      const reponse = await api.get<PaginatedResponse<Client>>(
        `/api/v1/agents/${advisorId}/clients/`,
        {
          params,
        },
      );
      return reponse.data;
    },
    enabled: !!advisorId,
  });
  useEffect(() => {
    clients.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters]);

  const setSearchFilter = debounce((searchText: string) => {
    if (searchText === "") {
      setFilters(undefined);
    } else if (searchText.length > 2) {
      setFilters(searchText);
    }
  }, 500);

  return (
    <>
      <Row className="mb-4">
        <Col span={16}>
          <span className="text-base">Clients</span>
          <button
            type="button"
            className="btn-link ml-4"
            onClick={() =>
              navigate(`/agency/${agencyId}/advisor/${advisorId}/client/add`)
            }
          >
            Add Client
          </button>
        </Col>
        <Col span={8}>
          <Input
            addonBefore={<SearchOutlined rev={undefined} />}
            placeholder="Filter Clients by Last Name..."
            onChange={(event) => {
              setSearchFilter(event.target.value);
            }}
          />
        </Col>
      </Row>
      <LoadingLine isShown={clients.isFetching} />
      <Row>
        <Col span={24}>
          {agencyId && advisorId && clients.data && !clients.isLoading ? (
            <Table
              style={{ cursor: "pointer" }}
              columns={[
                {
                  title: "First Name",
                  dataIndex: "firstName",
                },
                {
                  title: "Last Name",
                  dataIndex: "lastName",
                },
                {
                  title: "Phone",
                  dataIndex: "phone",
                },
                {
                  title: "Email",
                  dataIndex: "email",
                },
              ]}
              dataSource={clients.data.data}
              rowKey={(record) => record.id.toString()}
              onRow={(record: Client) => {
                return {
                  onClick: () =>
                    navigate(
                      `/agency/${agencyId}/advisor/${advisorId}/client/${record.id}`,
                    ),
                };
              }}
            />
          ) : (
            <Spin size="4xl" />
          )}
          <Pagination
            defaultCurrent={page + 1}
            total={clients.data?.count}
            current={page + 1}
            pageSize={perPage}
            hideOnSinglePage
            onChange={(page) => setPage(page - 1)}
          />
        </Col>
      </Row>
    </>
  );
};
