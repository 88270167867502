import { Fragment, useState, forwardRef, useImperativeHandle } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BucketReport, ReportCreateModalRef } from "types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api";
import { AxiosError } from "axios";
import { ReportDetailForm } from "components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type ReportCreateModalProps = {
  report: BucketReport;
};

export const _ReportUpdateModal: React.ForwardRefRenderFunction<
  ReportCreateModalRef,
  ReportCreateModalProps
> = (props, ref) => {
  const { report } = props;
  const api = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  const updateReport = useMutation<BucketReport, AxiosError, BucketReport>({
    mutationFn: async (values) => {
      const response = await api.put<BucketReport>(
        `/api/v1/clients/${report.customer.id}/bucket-reports/${report.id}/`,
        values,
      );
      return response.data;
    },
    onSuccess: (data) => {
      if (data.id !== report.id) {
        navigate(
          `/agency/${report.customer.agencyId}/advisor/${report.customer.agentId}/client/${report.customer.id}/report/${data.id}`,
        );
        toast.success("Report copied");
      } else {
        queryClient.invalidateQueries({
          queryKey: [
            "agency",
            report.customer.agencyId,
            "advisor",
            report.customer.agentId,
            "client",
            report.customer.id.toString(),
            "report",
            report.id.toString(),
          ],
        });
        toast.success("Report updated");
      }
    },
    onError: (error) => {
      if (error.response?.status === 400) {
        toast.error(
          "There was an error updating the report. Please try again.",
        );
      }
    },
  });

  const confirmCallback = (values: BucketReport) => {
    updateReport.mutate(values, {
      onSuccess: () => {
        cancel();
      },
    });
  };

  const cancel = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      setOpen: (open: boolean) => {
        setOpen(open);
      },
    };
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={cancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <ReportDetailForm
                  confirmCallback={confirmCallback}
                  cancel={cancel}
                  report={report}
                  title="Update Bucket Report Settings"
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const ReportUpdateModal = forwardRef(_ReportUpdateModal);
