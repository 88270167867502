import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { Agency, Agent, BucketReport, Client } from '../../types';
import { ClientDetailColumn } from './client_detail_column';
import { ReportSegments } from './report_segments';
import { ReportDetail } from './report_detail';
import { Spin } from 'components';
import { useApi } from 'api';
interface BucketToolPageProps {}

export const BucketToolPage: React.FC<BucketToolPageProps> = () => {
  const params = useParams<{ agencyId: string; advisorId: string; clientId: string; reportId: string }>();
  const api = useApi();

  const agency = useQuery<Agency>({
    queryKey: ['agency', params.agencyId],
    queryFn: async () => {
      const response = await api.get<{ data: Agency }>(`/api/v1/agencies/${params.agencyId}/`);
      return response.data.data;
    },
    enabled: !!params.agencyId,
  });

  const advisor = useQuery<Agent>({
    queryKey: ['agency', params.agencyId, 'advisor', params.advisorId],
    queryFn: async () => {
      const response = await api.get<{ data: Agent }>(`/api/v1/agents/${params.advisorId}/`);
      return response.data.data;
    },
    enabled: !!params.agencyId,
  });

  const client = useQuery<Client>({
    queryKey: ['agency', params.agencyId, 'advisor', params.advisorId, 'client', params.clientId],
    queryFn: async () => {
      const response = await api.get<{ data: Client }>(`/api/v1/clients/${params.clientId}/`);
      return response.data.data;
    },
    enabled: !!params.clientId,
  });

  const report = useQuery<BucketReport>({
    queryKey: [
      'agency',
      params.agencyId,
      'advisor',
      params.advisorId,
      'client',
      params.clientId,
      'report',
      params.reportId,
    ],
    queryFn: async () => {
      const response = await api.get<BucketReport>(
        `/api/v1/clients/${params.clientId}/bucket-reports/${params.reportId}/`
      );
      return response.data;
    },
    enabled: !!params.clientId && !!params.reportId,
  });

  return (
    <>
      {!report.data ||
      report.isLoading ||
      !agency.data ||
      agency.isLoading ||
      !client.data ||
      client.isLoading ||
      !advisor.data ||
      advisor.isLoading ? (
        <Spin size='4xl' />
      ) : (
        <div className='h-full'>
          <div>
            <span className='text-2xl'>
              <Link to={`/agency/${agency.data.id}`}>{agency.data.company}</Link> &gt;{' '}
              <Link to={`/agency/${agency.data.id}/advisor/${advisor.data.id}`}>{advisor.data.fullName}</Link> &gt;{' '}
              <Link to={`/agency/${agency.data.id}/advisor/${advisor.data.id}/client/${client.data.id}`}>
                <span className='text-teal-500'>{client.data.fullName}</span>
              </Link>
            </span>
          </div>
          <div className='w-full border-2 border-gray-300 rounded-md px-4 py-6 bg-gray-100'>
            {client.data && !client.isLoading ? <ClientDetailColumn client={client.data} /> : <Spin size='4xl' />}
          </div>
          <div className='flex mx-auto w-full h-full mt-2'>
            <div className='flex w-4/5 border-2 border-gray-300 rounded-md'>
              <ReportSegments segmentTypes={report.data?.segmentTypes ?? []} />
            </div>
            {report.data && !report.isLoading ? <ReportDetail report={report.data} /> : <Spin size='4xl' />}
          </div>
        </div>
      )}
    </>
  );
};
