import { ToastContainer } from 'react-toastify';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Header } from '../../components';
import { useAuth } from 'hooks';
import { AGENCY } from '../../constants';

interface ProfileManagementPageProps {}

export const ProfileManagementPage: React.FC<ProfileManagementPageProps> = () => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.loggedIn || !auth.roles.includes(AGENCY)) {
    const from = location.pathname;
    return <Navigate to={`/login?from=${from ?? '/'}`} />;
  }

  return (
    <div>
      <Header />
      <div className='w-full h-full py-4 px-8'>
        <ToastContainer />
        <div className='text-4xl'>Profile management</div>
        <Outlet />
      </div>
    </div>
  );
};
