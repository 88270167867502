import { Outlet } from 'react-router-dom';
import { ListAgencies } from './list_agencies';

interface AgenciesListProps {}
export const AgenciesList: React.FC<AgenciesListProps> = () => {
  return (
    <div className='flex w-full items-start gap-x-4 py-10'>
      <aside className='sticky hidden w-44 h-full shrink-0 md:block'>
        <ListAgencies />
      </aside>
      <main className='w-full'>
        <Outlet />
      </main>
    </div>
  );
};
