import { RefObject, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { InputField, Spin } from "components";
import { Form, Formik, FormikHelpers } from "formik";
import clsx from "clsx";
import {
  BucketReportSegment,
  ConfirmModalRef,
  PreviewModalRef,
  SegmentType,
} from "types";
import React from "react";
import { SegmentFormFields } from "./report_segment_fields";
import { validationSchema } from "./segment_validation_schemas";
import { isUndefined } from "lodash";

interface ReportSegmentProps {
  segment: BucketReportSegment;
  segmentTypes: SegmentType[];
  hasMaxLtcs: boolean;
  hasMaxVaults: boolean;
  index: number;
  editingSegment: string | undefined;
  handleSegmentEdit: (segmentId: string) => void;
  confirmModalRef: RefObject<ConfirmModalRef>;
  previewModalRef: RefObject<PreviewModalRef>;
  cancelAddSegment: () => void;
  cancelEditSegment: () => void;
  deleteSegment: () => void;
  handleSegmentSubmit: (
    values: any,
    helpers: FormikHelpers<BucketReportSegment>,
  ) => void;
}
export const ReportSegment: React.FC<ReportSegmentProps> = (props) => {
  const {
    segment,
    segmentTypes,
    hasMaxLtcs,
    hasMaxVaults,
    index,
    editingSegment,
    handleSegmentEdit,
    confirmModalRef,
    previewModalRef,
    cancelAddSegment,
    cancelEditSegment,
    deleteSegment,
    handleSegmentSubmit,
  } = props;
  const [deleting, setDeleting] = useState(false);

  const getStyle = (
    style: DraggingStyle | NotDraggingStyle | undefined,
    snapshot: DraggableStateSnapshot,
  ) => {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    // @ts-ignore
    const { moveTo, curve, duration } = snapshot.dropAnimation;
    // move to the right spot
    const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;

    // patching the existing style
    return {
      ...style,
      transform: `${translate}`,
      // slowing down the drop because we can
      transition: `all ${curve} ${duration + 1}s`,
    };
  };

  return (
    <Draggable
      isDragDisabled={!isUndefined(editingSegment)}
      key={`segment-${segment.id}`}
      draggableId={`segment-${segment.id}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className={clsx(
            "w-min-44 my-1 ml-1 flex h-full w-48 flex-col rounded-md border-2 border-gray-600 p-2.5",
            !isUndefined(editingSegment) && segment.id !== editingSegment
              ? "bg-gray-300"
              : "bg-white",
            snapshot.isDragging && "bg-teal-50",
            deleting && "bg-red-50",
          )}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(provided.draggableProps.style, snapshot)}
        >
          <div className="flex h-9 flex-row justify-center">
            <div className="w-8 flex-none pt-0.5 text-sm">
              {segment.id !== "new" && (
                <button
                  disabled={segment.id === "new"}
                  type="button"
                  className="text-gray-400 hover:text-teal-400 disabled:hover:text-gray-400"
                  onClick={() => {
                    previewModalRef.current?.setPreviewType("calc");
                    previewModalRef.current?.setSegmentData(segment);
                    previewModalRef.current?.setOpen(true);
                  }}
                >
                  {false ? (
                    <Spin />
                  ) : (
                    <FontAwesomeIcon icon={["fas", "calculator"]} />
                  )}
                </button>
              )}
            </div>
            <div className="flex-grow text-center text-gray-400 hover:text-teal-400">
              {isUndefined(editingSegment) && (
                <FontAwesomeIcon icon={["fas", "grip-lines"]} size="lg" />
              )}
            </div>
            <div className="w-8 flex-none text-right">
              {segment.id === editingSegment ? (
                <button
                  disabled={deleting}
                  type="button"
                  className="text-gray-400 hover:text-red-800 disabled:hover:text-gray-400"
                  onClick={() => {
                    setDeleting(true);
                    confirmModalRef.current?.setCancel(() =>
                      setDeleting(false),
                    );
                    confirmModalRef.current?.setMutate(() => {
                      deleteSegment();
                    });
                    confirmModalRef.current?.setOpen(true);
                  }}
                >
                  {deleting ? (
                    <Spin />
                  ) : (
                    <FontAwesomeIcon icon={["fas", "times"]} />
                  )}
                </button>
              ) : (
                <button
                  type="button"
                  disabled={!isUndefined(editingSegment)}
                  onClick={() => {
                    handleSegmentEdit(segment.id);
                  }}
                  className="btn-link mb-2 block w-full disabled:hover:bg-gray-300"
                >
                  Edit
                </button>
              )}
            </div>
          </div>
          <div className="flex h-full grow">
            <Formik
              enableReinitialize={segment.segmentType === "iul"}
              initialValues={{
                ...segment,
              }}
              validationSchema={() =>
                validationSchema(segment.segmentType, hasMaxVaults, hasMaxLtcs)
              }
              onSubmit={(values, formikHelpers) => {
                handleSegmentSubmit(values, formikHelpers);
              }}
            >
              {({
                values,
                isValid,
                dirty,
                isSubmitting,
                resetForm,
                setValues,
                setFieldValue,
                errors,
              }) => {
                return (
                  <Form className="flex flex-col justify-between">
                    <div className="flex grow flex-col">
                      <InputField
                        name="segmentType"
                        type="select"
                        label="Type"
                        disabled={
                          isUndefined(editingSegment) ||
                          segment.id !== editingSegment
                        }
                        value={values.segmentType}
                        selectValues={segmentTypes}
                        className="mb-2"
                        onBlur={() => {
                          if (values.segmentType !== segment.segmentType) {
                            resetForm({
                              values: {
                                ...values,
                                segmentData: {},
                              } as BucketReportSegment,
                            });
                          } else {
                            setValues(segment);
                          }
                        }}
                      />
                      <div className="pl-2 text-sm text-red-500">
                        {errors.segmentType}
                      </div>
                      <InputField
                        name="name"
                        type="text"
                        disabled={
                          isUndefined(editingSegment) ||
                          segment.id !== editingSegment
                        }
                        value={values.name || ""}
                        label="Name"
                        className="mb-2"
                      />
                      <SegmentFormFields
                        segmentType={values.segmentType}
                        segment={values}
                        setFieldValue={setFieldValue}
                        editingSegment={editingSegment}
                        previewModalRef={previewModalRef}
                      />
                    </div>
                    <div className="flex shrink flex-row gap-2">
                      {!isUndefined(editingSegment) &&
                        segment.id === editingSegment && (
                          <>
                            <button
                              disabled={isSubmitting}
                              type="button"
                              onClick={() => {
                                if (values.id === "new") {
                                  cancelAddSegment();
                                } else {
                                  cancelEditSegment();
                                  resetForm({ values: segment });
                                }
                              }}
                              className="btn-outline mb-2 block w-full disabled:cursor-default disabled:hover:bg-gray-300"
                            >
                              {isSubmitting ? <Spin /> : "Cancel"}
                            </button>
                            <button
                              disabled={!isValid || !dirty || isSubmitting}
                              type="submit"
                              className="btn-primary block w-full"
                            >
                              {isSubmitting ? <Spin /> : "Save"}
                            </button>
                          </>
                        )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </Draggable>
  );
};
