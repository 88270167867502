import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { InputField, Spin } from "components";
import * as Yup from "yup";
import { Agency } from "../../types";
import { useApi } from "api";

interface AgencyFormProps {
  initialValues: Omit<Agency, "id">;
  mutateFunction: (values: Omit<Agency, "id">) => void;
  goBack: () => void;
}

export const AgencyForm: React.FC<AgencyFormProps> = (props) => {
  const { initialValues, mutateFunction, goBack } = props;
  const api = useApi();

  const states = useQuery({
    queryKey: ["states"],
    queryFn: async () => {
      const response =
        await api.get<{ id: string; name: string }[]>("/api/v1/states/");
      return response.data;
    },
  });

  if (!states.data || states.isLoading) return <Spin size="4xl" />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        mutateFunction(values);
      }}
      validationSchema={Yup.object().shape({
        company: Yup.string()
          .min(3, "Must be at least 3 characters long.")
          .required("This field is required"),
        address: Yup.string().nullable(),
        address2: Yup.string().nullable(),
        state: Yup.string().required("This field is required"),
        postal: Yup.string()
          .min(5, "Must be 5 characters long.")
          .max(5, "Must be 5 characters long.")
          .matches(/^\d{5}$/, "Postal code must only contain digits.")
          .nullable(),
        email: Yup.string().email("Must be a valid email address").nullable(),
        phone: Yup.string()
          .min(10, "Phone number must have 10 digits.")
          .matches(/^\d{10}$/, "Phone number must only contain digits.")
          .nullable(),
      })}
    >
      {({ isValid, dirty, isSubmitting, values }) => {
        return (
          <Form>
            <div className="py-4 text-base text-gray-600">
              Agency Information
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="col-span-1 md:col-span-2">
                <InputField name="company" label="Company*" type="text" />
              </div>
              <InputField name="address" label="Address 1" type="text" />
              <InputField name="address2" label="Address 2" type="text" />
              <InputField
                name="state"
                label="State*"
                type="select"
                selectValues={states.data ?? []}
              />
              <InputField name="postal" label="Postal Code" type="text" />
              <InputField name="email" label="Email" type="text" />
              <InputField name="phone" label="Phone number" type="text" />
            </div>
            <div className="flex flex-row justify-end gap-4 py-4">
              <button
                type="button"
                onClick={() => goBack()}
                className="btn-outline w-40"
                disabled={!isValid || isSubmitting}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-primary w-40"
                disabled={!isValid || !dirty || isSubmitting}
              >
                Save
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
