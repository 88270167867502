import React, { forwardRef, useImperativeHandle, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ArrayHelpers, FieldArray, Form, Formik } from 'formik';
import { InputField } from 'components';
import { RecommendModalRef } from 'types';

type ModalRecommendProps = { saveRecommendations: (recommendations: string[]) => void };

const _RecommendModal: React.ForwardRefRenderFunction<RecommendModalRef, ModalRecommendProps> = (props, ref) => {
  const { saveRecommendations } = props;
  const [open, setOpen] = useState(false);
  const [recommendations, setRecommendations] = useState<string[]>([]);
  useImperativeHandle(ref, () => {
    return {
      setRecommendations: (recommendations: string[]) => {
        setRecommendations(recommendations);
      },
      setOpen: (open: boolean) => {
        setOpen(open);
      },
    };
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 w-1/2 h-1/4'>
                <div className='absolute right-0 top-0 hidden pr-4 pt-4 sm:block'>
                  <button
                    type='button'
                    className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                    onClick={() => setOpen(false)}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>
                <div className='sm:flex sm:items-start'>
                  <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full'>
                    <Dialog.Title as='h3' className='text-base font-semibold leading-6 text-gray-900 capitalize'>
                      Recommendations
                    </Dialog.Title>
                    <div className='mt-2'>
                      <Formik
                        initialValues={{
                          recommendations,
                        }}
                        onSubmit={(values, actions) => {
                          saveRecommendations(values.recommendations);
                          actions.setSubmitting(false);
                        }}
                      >
                        {({ values }) => (
                          <Form>
                            <FieldArray
                              name='recommendations'
                              render={(arrayHelpers: ArrayHelpers) => (
                                <div className='flex-grow'>
                                  {values.recommendations &&
                                    values.recommendations.length > 0 &&
                                    values.recommendations.map((recommendation, index) => (
                                      <div className='flex flex-row mb-2' key={`recommendations.${index}`}>
                                        <InputField
                                          type='text'
                                          name={`recommendations.${index}`}
                                          label={`Recommendation ${index + 1}`}
                                          className='flex-grow block w-full'
                                        />
                                        <button
                                          className='btn-outline h-10 w-10 ml-2'
                                          type='button'
                                          onClick={() => arrayHelpers.remove(index)}
                                        >
                                          <TrashIcon className='h-5 w-5' />
                                        </button>
                                      </div>
                                    ))}
                                  {values.recommendations.length < 3 && (
                                    <button type='button' onClick={() => arrayHelpers.push('')} className='btn-outline'>
                                      Add a recommendation
                                    </button>
                                  )}
                                </div>
                              )}
                            />
                            <div className='flex-shrink-0 mt-4'>
                              <button type='submit' className='btn-primary w-24'>
                                Save
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const RecommendModal = forwardRef(_RecommendModal);
