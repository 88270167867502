import { ClientList } from './client_list';
import { Link, useParams } from 'react-router-dom';
import { Agent } from '../../types';
import { useQuery } from '@tanstack/react-query';
import { Spin } from 'components';
import { useApi } from 'api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AdvisorDetail = () => {
  const params = useParams<{ agencyId: string; advisorId: string }>();
  const api = useApi();

  const advisor = useQuery<Agent>({
    queryKey: ['agency', params.agencyId, 'advisor', params.advisorId],
    queryFn: async () => {
      const response = await api.get<{ data: Agent }>(`/api/v1/agents/${params.advisorId}/`);
      return response.data.data;
    },
    enabled: !!params.agencyId,
  });

  return (
    <div className='flex w-full'>
      {!advisor.data || advisor.isLoading ? (
        <Spin size='4xl' />
      ) : (
        <div className='w-full p-2 border border-gray-300 rounded-md'>
          <div className='flex flex-row'>
            <div className='grow'>
              <Link
                to={`/agency/${params.agencyId}`}
                className='mb-4 text-gray-500 hover:no-undeline hover:text-teal-700 text-base'
              >
                <FontAwesomeIcon icon={['fas', 'chevron-left']} className='ml-1 pt-1' color='#999' />{' '}
                {advisor.data.agency?.company || <Spin />}
              </Link>
            </div>
          </div>
          <div className='flex flex-row'>
            <div className='grow'>
              <span className='text-teal-400 text-4xl'>{advisor.data.fullName}</span>{' '}
              <span className='text-gray-400'>Advisor</span>
            </div>
            <div>
              <Link to={`/agency/${params.agencyId}/advisor/${params.advisorId}/edit`}>
                <div className='btn-link'>Edit</div>
              </Link>
            </div>
          </div>
          <div>
            <div>{advisor.data.email}</div>
          </div>
          <div>
            <div>{advisor.data.phone}</div>
          </div>
          <div>
            <div>{advisor.data.fax}</div>
          </div>
          <br />
          <div>
            <div>
              <ClientList agencyId={params.agencyId} advisorId={params.advisorId} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
