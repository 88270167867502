import { useMutation, useQuery } from '@tanstack/react-query';
import { Spin } from '../../components';
import { useApi } from 'api';
import { Form, Formik } from 'formik';
import { InputField } from 'components';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

export const ProfilePage = () => {
  const api = useApi();

  const updatePassword = useMutation({
    mutationFn: async (values: { currentPassword: string; password: string; confirm: string }) => {
      await api.post('/api/v1/change-password/', values);
    },
    onSuccess: () => {
      toast.success('Password updated');
    },
    onError: () => {
      toast.error('Error updating password');
    },
  });

  const me = useQuery<{ username: string; email: string; roles: { name: string }[] }>({
    queryKey: ['me'],
    queryFn: async () => {
      const response = await api.get<{ username: string; email: string; roles: { name: string }[] }>('/api/v1/me/');
      return response.data;
    },
  });

  return (
    <div className='flex w-full'>
      {!me.data || me.isLoading ? (
        <Spin size='4xl' />
      ) : (
        <div className='w-full max-w-7xl p-2 border border-gray-300 rounded-md'>
          <div className='flex flex-row'>
            <div className='grow'>
              <span className='text-teal-400 text-4xl'>{me.data.username}</span>{' '}
              <span className='text-gray-400'>Advisor</span>
            </div>
          </div>
          <div>
            <div>{me.data.email}</div>
          </div>
          <div className='py-4 text-gray-600 text-base'>Roles</div>
          <div>
            <div>
              <ul>
                {me.data.roles.map((role) => (
                  <li>{role.name}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className='py-4 text-gray-600 text-base'>Security</div>
          <div>
            <Formik
              initialValues={{ currentPassword: '', password: '', confirm: '' }}
              validationSchema={Yup.object({
                currentPassword: Yup.string().required('Required'),
                password: Yup.string().required('Required'),
                confirm: Yup.string()
                  .oneOf([Yup.ref('password')], 'Passwords must match')
                  .required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                updatePassword.mutate(values);
                setSubmitting(false);
                resetForm();
              }}
            >
              {({ isSubmitting }) => (
                <Form className='w-96 grid grid-cols-1 gap-4'>
                  <InputField name='currentPassword' label='Current Password' type='password' />
                  <InputField name='password' label='Password' type='password' />
                  <InputField name='confirm' label='Confirm Password' type='password' />
                  <button type='submit' disabled={isSubmitting} className='btn-primary block'>
                    Change Password
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};
