import { useLocation, useOutlet, useParams, useSearchParams } from 'react-router-dom';
import { AuthProvider } from '../../hooks';

export const AuthLayout = () => {
  const outlet = useOutlet();
  const location = useLocation();
  const params = useParams();
  // eslint-disable-next-line
  const [URLSearchParams, _] = useSearchParams();

  if (process.env.NODE_ENV === 'development') {
    console.log(
      `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
        location.state
      )}, Params: ${JSON.stringify(params)}, Query Params: ${JSON.stringify(URLSearchParams.toString())}`
    );
  }

  return <AuthProvider>{outlet}</AuthProvider>;
};
