import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Agent } from "../../types";
import { toast } from "react-toastify";
import { AdvisorForm } from "./form";
import { useApi } from "api";

export const AdvisorAdd = () => {
  const navigate = useNavigate();
  const api = useApi();
  const params = useParams<{ agencyId: string }>();
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: async (values: Omit<Agent, "id" | "fullName" | "agency">) => {
      const response = await api.post<{ data: { id: number } }>(
        `/api/v1/agencies/${params.agencyId}/agents/`,
        { ...values },
      );
      return response.data.data;
    },
    onSuccess: (data: { id: number }) => {
      toast.success("Advisor created");
      queryClient.invalidateQueries({
        queryKey: ["agency", params.agencyId, "advisor"],
      });
      navigate(`/agency/${params.agencyId}/advisor/${data.id}`);
    },
    onError: () => {
      toast.error("Error creating advisor");
    },
  });

  return (
    <div className="w-full rounded-md border border-gray-300 p-2">
      <div>
        <span className="text-4xl text-teal-400">New Advisor</span>{" "}
        <span className="text-gray-600">Advisor</span>
      </div>
      <AdvisorForm
        mutateFunction={(values) => create.mutate(values)}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        }}
        goBack={() => navigate(`/agency/${params.agencyId}`)}
      />
      <br />
    </div>
  );
};
