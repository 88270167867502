import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Spin } from "components";
import { FieldHookConfig, useField } from "formik";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface FileFieldProps {
  name: string;
  value?: string;
  className?: string;
  fieldClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  uploadFile?: (file: File) => void;
  viewFileValues?: () => void;
}

export const FileField: React.FC<FileFieldProps> = (props) => {
  const {
    name,
    value,
    className,
    disabled,
    loading,
    fieldClassName,
    uploadFile,
    viewFileValues,
  } = props;

  const config: FieldHookConfig<any> = { name };
  if (value) {
    config.value = value;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(config);

  const onFileDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!uploadFile) {
        return;
      }
      uploadFile(acceptedFiles[0]);
    },
    [uploadFile],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onFileDrop });

  const extensionValidator = (file: File) => {
    const extension = file.name.split(".").pop();
    return ["csv", "xlsx"].includes(extension || "none");
  };

  return (
    <div className={fieldClassName}>
      <div className={clsx(className)}>
        {meta.value !== undefined && (
          <div
            className={clsx(
              "mt-4 flex h-28 flex-col justify-around text-center align-middle text-sm",
            )}
          >
            <button onClick={viewFileValues} type="button">
              <div className="btn-link flex flex-col">
                <FontAwesomeIcon icon={["fas", "file-csv"]} size="2x" />
                View Parsed Values
              </div>
            </button>
          </div>
        )}
        {loading ? (
          <div className="mt-4 flex h-28 flex-col justify-center rounded-md border-2 border-dashed border-gray-300 pt-8 text-center align-middle">
            <Spin size="4xl" color="teal" />
          </div>
        ) : (
          <div
            className={clsx(
              "mt-4 flex h-28 flex-col justify-center rounded-md border-2 border-dashed border-gray-300 text-center align-middle text-sm",
              disabled
                ? "cursor-not-allowed hover:border-gray-300"
                : "cursor-pointer hover:border-teal-300",
              meta.error && "border-red-500 bg-red-100",
            )}
            {...getRootProps({
              multiple: false,
              validator: extensionValidator,
              accept:
                "text/*, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })}
            title={meta.error}
          >
            <input {...getInputProps()} disabled={disabled} />
            Drop .csv file here or click to upload
          </div>
        )}
      </div>
    </div>
  );
};
