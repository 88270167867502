import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Agency } from '../../types';
import { toast } from 'react-toastify';
import { AgencyForm } from './form';
import { Spin } from 'components';
import { useApi } from 'api';

export const AgencyEdit: React.FC = () => {
  const navigate = useNavigate();
  const api = useApi();
  const params = useParams<{ agencyId: string }>();
  const queryClient = useQueryClient();

  const update = useMutation({
    mutationFn: async (values: Omit<Agency, 'id'>) => {
      await api.put<Agency>(`/api/v1/agencies/${params.agencyId}/`, values);
    },
    onSuccess: () => {
      toast.success('Agency updated successfully');
      queryClient.invalidateQueries({ queryKey: ['agency'] });
      navigate(`/agency/${params.agencyId}`);
    },
    onError: () => {
      toast.error('Error updating agency');
    },
  });

  const agency = useQuery<Agency>({
    queryKey: ['agency', params.agencyId],
    queryFn: async () => {
      const reponse = await api.get<{ data: Agency }>(`/api/v1/agencies/${params.agencyId}/`);
      return reponse.data.data;
    },
    enabled: !!params.agencyId,
  });

  return (
    <>
      {!agency.data || agency.isLoading ? (
        <Spin size='4xl' />
      ) : (
        <div className='w-full max-w-7xl p-2 border border-gray-300 rounded-md'>
          <div>
            <span className='text-teal-400 text-4xl'>{agency.data.company}</span>{' '}
            <span className='text-gray-600'>Agency</span>
          </div>
          <AgencyForm
            mutateFunction={(values) => update.mutate(values)}
            initialValues={{
              company: agency.data.company,
              email: agency.data.email,
              address: agency.data.address,
              address2: agency.data.address2,
              state: agency.data.state,
              postal: agency.data.postal,
              phone: agency.data.phone,
            }}
            goBack={() => navigate(`/agency/${params.agencyId}`)}
          />
        </div>
      )}
    </>
  );
};
