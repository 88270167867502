import { useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  PaginatedResponse,
  BucketReport,
  ReportCreateModalRef,
} from "../../types";
import { Pagination } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Spin } from "components";
import { useApi } from "api";
import { ReportCreateModal } from "./report_create_modal";
import numeral from "numeral";

interface BucketListProps {
  agencyId: string | undefined;
  advisorId: string | undefined;
  clientId: string | undefined;
}
export const BucketList: React.FC<BucketListProps> = (props) => {
  const { agencyId, advisorId, clientId } = props;
  const navigate = useNavigate();
  const api = useApi();
  const [page, setPage] = useState(0);
  const perPage = 20;
  const sorted = null;
  const reportCreateModalRef = useRef<ReportCreateModalRef>(null);

  const buckets = useQuery<PaginatedResponse<BucketReport>>({
    queryKey: [
      "agency",
      agencyId,
      "advisor",
      advisorId,
      "client",
      clientId,
      "report",
    ],
    queryFn: async () => {
      const response = await api.get<PaginatedResponse<BucketReport>>(
        `/api/v1/clients/${clientId}/bucket-reports/`,
        {
          params: { page, per_page: perPage, sorted },
        },
      );
      return response.data;
    },
    enabled: !!clientId,
  });

  return (
    <>
      <ReportCreateModal
        clientId={clientId}
        agencyId={agencyId}
        advisorId={advisorId}
        ref={reportCreateModalRef}
      />
      <div className="mb-4">
        <span className="text-base">Bucket Reports</span>
        <button
          type="button"
          className="btn-link ml-4"
          onClick={() => reportCreateModalRef.current?.setOpen(true)}
        >
          New Report
        </button>
      </div>
      {buckets.data && !buckets.isLoading ? (
        <>
          {buckets.data.data.map((bucket) => (
            <div
              key={bucket.id}
              className="mb-2 w-full cursor-pointer rounded-md border-2 border-gray-300 p-4 shadow-md hover:bg-teal-100"
              onClick={() =>
                navigate(
                  `/agency/${agencyId}/advisor/${advisorId}/client/${clientId}/report/${bucket.id}`,
                )
              }
            >
              <div className="grid grid-cols-4 gap-x-8">
                <div className="text-sm text-gray-500">Report name</div>
                <div></div>
                <div className="text-right text-sm text-gray-500">Created</div>
                <div className="text-right text-sm text-gray-500">Modified</div>
                <div className="mb-2 text-sm text-gray-700">{bucket.name}</div>
                <div></div>
                <div className="mb-2 text-right text-sm text-gray-700">
                  {moment(bucket.createdOn).format("MM/DD/YYYY LT")}
                </div>
                <div className="mb-2 text-right text-sm text-gray-700">
                  {moment(bucket.modifiedOn).format("MM/DD/YYYY LT")}
                </div>
                <div className="text-right text-sm text-gray-500">
                  Total Required
                </div>
                <div className="text-right text-sm text-gray-500">
                  Ending Balance
                </div>
                <div className="text-right text-sm text-gray-500">
                  Years of Income
                </div>
                <div className="text-right text-sm text-gray-500">
                  Total Income Received
                </div>
                <div className="mb-2 text-right text-sm text-gray-700">
                  {numeral(bucket.stats.totalPremium).format("$0,0")}
                </div>
                <div className="mb-2 text-right text-sm text-gray-700">
                  {numeral(bucket.stats.totalBalance).format("$0,0")}
                </div>
                <div className="mb-2 text-right text-sm text-gray-700">
                  {numeral(bucket.stats.incomeYears).format("0,0")}
                </div>
                <div className="mb-2 text-right text-sm text-gray-700">
                  {numeral(bucket.stats.totalIncome).format("$0,0")}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <Spin size="4xl" />
      )}
      <Pagination
        defaultCurrent={page + 1}
        total={buckets.data?.count || 0}
        current={page + 1}
        pageSize={perPage}
        hideOnSinglePage
        onChange={(page) => setPage(page - 1)}
      />
    </>
  );
};
