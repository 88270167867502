import React from "react";
import ReactDOM from "react-dom/client";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faChevronLeft,
  faCalculator,
  faEdit,
  faEye,
  faFileCsv,
  faPlusSquare,
  faSpinner,
  faTimesCircle,
  faTimes,
  faImage,
  faInfoCircle,
  faCaretDown,
  faGripLines,
} from "@fortawesome/free-solid-svg-icons";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

library.add(
  faCalculator,
  faSpinner,
  faEye,
  faEdit,
  faFileCsv,
  faPlusSquare,
  faChevronLeft,
  faCheckCircle,
  faTimesCircle,
  faTimes,
  faImage,
  faInfoCircle,
  faGripLines,
  faCaretDown,
);

const NeedStorage = () => {
  return (
    <div>
      <h3>Localstorage is required for this app to function properly</h3>
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    {typeof Storage !== "undefined" ? <App /> : <NeedStorage />}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
