import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { AssetIncomeSourceType } from '../../types';

interface MenuButtonProps {
  buttonText: string;
  menuOptions: { id: string; name: string }[];
  selectHandler: (option: AssetIncomeSourceType) => void;
}

export const MenuButton: React.FC<MenuButtonProps> = ({ menuOptions, buttonText, selectHandler }) => {
  return (
    <Listbox onChange={selectHandler}>
      {({ open }) => (
        <>
          <Listbox.Label className='sr-only'>{buttonText}</Listbox.Label>
          <div className='relative'>
            <div className='inline-flex divide-x divide-teal-700 rounded-md shadow-sm'>
              <div className='inline-flex items-center gap-x-1.5 rounded-l-md bg-teal-600 px-3 py-2 text-white shadow-sm'>
                <p className='text-sm font-semibold'>{buttonText}</p>
              </div>
              <Listbox.Button className='inline-flex items-center rounded-l-none rounded-r-md bg-teal-600 p-2 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2 focus:ring-offset-gray-50'>
                <span className='sr-only'>{buttonText}</span>
                <ChevronDownIcon className='h-5 w-5 text-white' aria-hidden='true' />
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                {menuOptions.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      clsx(
                        active ? 'bg-teal-600 text-white' : 'text-gray-900',
                        'cursor-default select-none p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className='flex flex-col'>
                        <div className='flex justify-between'>
                          <p className='font-normal'>{option.name}</p>
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
