import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { camelizeKeys } from "humps";
// import camelcaseKeys from "camelcase-keys";
import decamelizeKeys from "decamelize-keys";
// import { AuthSuccessPayload } from "../types";
import { BASE_URL, APP_VERSION } from "../constants";
import { useAuth } from "hooks";
import { toast } from "react-toastify";
import LogRocket from "logrocket";

type GenericDict = { [key: string]: any };
// type RequestFunc = (
//   resource: string,
//   params?: GenericDict,
//   options?: GenericDict,
// ) => Promise<any>;

export function useApi() {
  const auth = useAuth();

  const request = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      AppVersion: APP_VERSION,
    },
  });

  request.interceptors.request.use(
    async (config) => {
      if (auth.token) {
        config["headers"]["Authorization"] = `JWT ${auth.token}`;
      }
      if (config["headers"]["Content-Type"] === "application/json") {
        config.data = decamelizeKeys(config.data, {
          exclude: ["address2"],
          deep: true,
        });
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  request.interceptors.response.use(
    async (response) => {
      return camelizeKeys(response) as AxiosResponse<any>;
    },
    async (error) => {
      const originalConfig = error.config;
      if (
        !["/api/v1/auth-refresh/", "/api/v1/auth/"].includes(
          originalConfig.url,
        ) &&
        error.response
      ) {
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            await auth.refreshAuth();
            return request(originalConfig);
          } catch (err) {
            auth.logout();
            return Promise.reject(err);
          }
        }
      } else {
        return Promise.reject(error);
      }
    },
  );

  const get = async <T>(
    resource: string,
    params?: GenericDict,
    options: GenericDict = {},
  ): Promise<AxiosResponse<T>> => {
    return await request<T>({
      method: "get",
      url: resource,
      config: request.defaults,
      ...params,
      ...options,
    } as AxiosRequestConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  };

  const post = async <T>(
    resource: string,
    payload: GenericDict,
    options: GenericDict = {},
  ): Promise<AxiosResponse<T>> => {
    return await request<T>({
      method: "post",
      url: resource,
      data: payload,
      config: request.defaults,
      ...options,
    } as AxiosRequestConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  };

  const put = async <T>(
    resource: string,
    payload: GenericDict,
    options: GenericDict = {},
  ): Promise<AxiosResponse<T>> => {
    return await request<T>({
      method: "put",
      url: resource,
      data: payload,
      config: request.defaults,
      ...options,
    } as AxiosRequestConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  };

  const _delete = async (
    resource: string,
    options: GenericDict = {},
  ): Promise<AxiosResponse> => {
    // an underscore is used to avoid conflict with the delete function in the axios library
    return await request({
      method: "delete",
      url: resource,
      config: request.defaults,
      ...options,
    } as AxiosRequestConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  };

  const handleError = (error: AxiosError<{ errorMessage: string }>) => {
    let errorMessage = `${error}`;
    if (error.hasOwnProperty("response")) {
      errorMessage = error.response?.data?.errorMessage ?? "";
    }
    if (error.hasOwnProperty("message")) {
      errorMessage = error.message;
    }
    toast.error(errorMessage);
    LogRocket.captureException(error);
    throw errorMessage;
  };

  return {
    get,
    post,
    put,
    _delete,
    handleError,
  };
}

// const logoutUser = () => {
//   localStorage.removeItem("token");
//   localStorage.removeItem("refreshToken");
// };

// const refreshToken = async () => {
//   const refreshToken = localStorage.getItem("refreshToken");
//   const data: AuthSuccessPayload = await request.post("/api/v1/auth-refresh/", {
//     token: refreshToken,
//   });
//   localStorage.setItem("token", data.token);
// };

// export const request = axios.create({
//   baseURL: BASE_URL,
//   timeout: 10000,
//   withCredentials: true,
//   headers: {
//     "Content-Type": "application/json",
//     Accept: "application/json",
//     AppVersion: APP_VERSION,
//   },
// });

// request.interceptors.request.use(
//   async (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config["headers"]["Authorization"] = `JWT ${token}`;
//     }
//     if (config["headers"]["Content-Type"] === "application/json") {
//       config.data = decamelizeKeys(config.data, {
//         split: /(?=[A-Z])|(?=(?<![1-9_])[1-9])/,
//       });
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   },
// );

// request.interceptors.response.use(
//   async (response) => {
//     return camelizeKeys(response) as AxiosResponse<any>;
//   },
//   async (error) => {
//     const originalConfig = error.config;
//     if (
//       !["/api/v1/auth-refresh/", "/api/v1/auth/"].includes(
//         originalConfig.url,
//       ) &&
//       error.response
//     ) {
//       if (error.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         try {
//           await refreshToken();
//           return request(originalConfig);
//         } catch (err) {
//           logoutUser();
//           return Promise.reject(err);
//         }
//       }
//     } else {
//       return Promise.reject(error);
//     }
//   },
// );

// export const get: RequestFunc = async (resource, params, options = {}) => {
//   return await request({
//     method: "get",
//     url: resource,
//     config: request.defaults,
//     ...params,
//     ...options,
//   } as AxiosRequestConfig)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };

// export const post: RequestFunc = async (resource, payload, options = {}) => {
//   return await request({
//     method: "post",
//     url: resource,
//     data: payload,
//     config: request.defaults,
//     ...options,
//   } as AxiosRequestConfig)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };

// export const put: RequestFunc = async (resource, payload, options = {}) => {
//   return await request({
//     method: "put",
//     url: resource,
//     data: payload,
//     config: request.defaults,
//     ...options,
//   } as AxiosRequestConfig)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };

// export const _delete = async (resource: string, options = {}) => {
//   // an underscore is used to avoid conflict with the delete function in the axios library
//   return await request({
//     method: "delete",
//     url: resource,
//     config: request.defaults,
//     ...options,
//   } as AxiosRequestConfig)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };

// export const handleError = (error: AxiosError<{ errorMessage: string }>) => {
//   if (error.hasOwnProperty("response")) {
//     throw error.response?.data?.errorMessage;
//   }
//   if (error.hasOwnProperty("message")) {
//     throw error.message;
//   }
//   throw error;
// };
