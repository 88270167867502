import React, { useState, useContext, createContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Auth, AuthSuccessPayload } from "../../types";
import { useApi } from "api";
import { useNavigate } from "react-router-dom";
import LogRocket from "logrocket";

export interface AuthContextInterface {
  loggedIn: boolean;
  username: string;
  roles: string[];
  token: string;
  refreshToken: string;
  login: (username: string, password: string) => void;
  logout: () => Promise<void>;
  refreshAuth: () => Promise<void>;
  changePassword: (data: {
    currentPassword: string;
    password: string;
    confirm: string;
  }) => void;
}

const BASE_AUTH_STATE = {
  loggedIn: false,
  username: "",
  roles: [],
  token: "",
  refreshToken: "",
};

const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface,
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const useAuthProvider = (): AuthContextInterface => {
  const api = useApi();
  const navigate = useNavigate();
  const [authState, setAuthState] = useState<Auth>(BASE_AUTH_STATE);

  useEffect(() => {
    const _authState = localStorage.getItem("authState");
    if (!authState.loggedIn && _authState) {
      setAuthState(JSON.parse(_authState));
      LogRocket.identify(authState.username, {
        roles: authState.roles.join("|") || "",
      });
    }
  }, [authState.loggedIn, authState.username, authState.roles]);

  useEffect(() => {
    console.log("auth state", authState);
  }, [authState]);

  const login = (username: string, password: string): void => {
    api
      .post<AuthSuccessPayload>("/api/v1/auth/", { username, password })
      .then(async (response) => {
        if (response?.status === 200) {
          const _authState = {
            loggedIn: true,
            ...response?.data,
            roles: response?.data.roles.map((r: { name: string }) => r.name),
          };
          setAuthState(_authState);
          LogRocket.identify(username, {
            roles: authState.roles.join("|") || "",
          });
          localStorage.setItem("authState", JSON.stringify(_authState));
          navigate("/");
        }
      });
  };

  const refreshAuth = async () => {
    const response = await api.post<{ token: string }>(
      "/api/v1/auth-refresh/",
      {
        token: authState.refreshToken,
      },
    );
    localStorage.setItem(
      "authState",
      JSON.stringify({ ...authState, token: response?.data.token }),
    );
  };

  const logout = async () => {
    localStorage.removeItem("authState");
    setAuthState(BASE_AUTH_STATE);
    toast.success("Logged out");
  };

  const changePassword = (data: {
    currentPassword: string;
    password: string;
    confirm: string;
  }) => {
    return api.post("/api/v1/change-password/", data);
  };

  return {
    loggedIn: authState.loggedIn,
    username: authState.username,
    roles: authState.roles,
    token: authState.token,
    refreshToken: authState.refreshToken,
    login,
    logout,
    changePassword,
    refreshAuth,
  } as AuthContextInterface;
};
